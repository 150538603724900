import React from 'react';

import BlockContent from 'molecules/BlockContent';
import Container from 'molecules/Container';
import SEO from 'molecules/SEO';

import { pageMetaFields } from 'lib/fragments';

import './index.scss';


/**
 * Default template.
 */
export const query = graphql`
 query ( $id: String ) {
   page: sanityPage( id: { eq: $id } ){
     id
     slug {
       current
     }
     title
     _rawPageTemplate(resolveReferences: { maxDepth: 10 })
     ...pageMetaFields
   }
 }
`;
export default function DefaultTemplate( { data, path } ) {

  const { page } = data;
  const {
    meta,
    title,
    _rawPageTemplate: {
      Default: {
        Content: content,
      }
    }
  } = page;

  return(

    <div className="Default">

      <SEO
      title={ title }
      path={ path }
      { ...meta }
      />

      <Container size="sm">

        <h1 className="Default__title h-xl">
          { title }
        </h1>

        <div className="Default__content p-md">
          <BlockContent
          blocks={ content }
          />
        </div>

      </Container>
    </div>

  )


}